
<template>
  <nav id="navHome" style="display:none;">    
  </nav>
  <router-view/>
<nav class="shadow navbar navbar-expand-lg a-nav bg-white fixed-top" aria-label=" ">
    <div class="container-xl">
      <a class="navbar-brand a-nav me-5" href="#">
            <img :src="imageSrc1" class="zoom img-fluid " alt="logo" style="position: inherit; width: 135px;">
          </a>
       <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class=""><h3><i class="bi bi-list"></i></h3>
    </span>
    </button>

      <div class="row collapse navbar-collapse ms-5" id="navbarSupportedContent">
        <ul class="navbar-nav mb-2">
          <li class="nav-item me-5">
            <a class="nav-link active" aria-current="page" href="#inicio-top">Inicio</a>
          </li>        
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#servicios-top">Servicios</a>
          </li>  
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#consultoria-top">Consultoría</a>
          </li> 
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#productos-top">Productos</a>
          </li> 
          <li class="nav-item mx-5">
            <a class="nav-link active" aria-current="page" href="#contacto">Contacto</a>
          </li>
        </ul>
      </div>
      
    </div>
  </nav>
  <router-view/>
  <div id="inicio-top" class="container col-xxl-8 px-4 col-sm-12 mt-5">
    <div id="presentacion" class="row mt-3 flex-lg-row-reverse align-items-center g-5 ">
      <div class="col-md-10 col-sm-8 ms-auto col-lg-4">
        <img src="../assets/images/services/unifi6.webp" class="zoom mt-5 d-block mx-auto mx-lg-auto img-fluid img-responsive" alt="" width="450" height="650" loading="lazy">
      </div>
      <div class="col-lg-8 col-md-8 col-sm-12 text-end">
        <h1 class="fw-bold lh-1 mb-3 ">productos y servicios</h1>
        <p class="p-hero fw-light " style="font-family: 'GothamLight';color: black;">Ofrecemos la última tecnología en el mercado para impulsar la mejora continua de los procesos de nuestros clientes, manteniéndonos siempre a la vanguardia.</p>
        <div class="d-grid gap-2 d-md-flex justify-content-md-end">
        </div>
      </div>
    </div>
  </div>
  
  <div class="container pt-4 px-4 " id="servicios-top">
    <div class="row g-4 py-4 row-cols-1 row-cols-lg-4">
      <div class="feature col">
        <h2>Servicios</h2>
      </div>
      <div class="zoom feature col">
        <div class="feature-icon">
          <img src="../assets/images/services/laptop.svg" class="d-block ms-lg-auto img-fluid" alt="" height="10" loading="lazy">
        </div>
        <h3>seguridad</h3>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Diseñados para proteger las redes corporativas y domésticas contra intrusos, malware y otras amenazas.</p>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Protección de datos y copia de seguridad.</p>
      </div>
      <div class="zoom feature col">
        <div class="feature-icon">
          <img src="../assets/images/services/consultor.svg" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy">
        </div>
        <h3>Consultoría</h3>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Consultoría especializada en las necesidades del cliente.</p>
      </div>
      <div class="zoom feature col">
        <div class="feature-icon">
          <img src="../assets/images/services/server.svg" class="d-block ms-lg-auto img-fluid" alt="" height="50" loading="lazy">
        </div>
        <h3>equipos de cómputo y servidores</h3>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Cómputo e impresión para ofrecer diversidad de soluciones a las empresas.</p>
        <p class="p-hero text-end" style="font-family: 'GothamLight';color: black;">Mantenimiento correctivo y preventivo.</p>
      </div>
    </div>
  </div>
  <div class="container px-4" id="featured-10">
    <div class="row g-4 py-1 row-cols-1 row-cols-lg-4">
      <div class="row col-md-12 p-lg-5 mx-auto">
        <div class="col-md-6 col-sm-12 p-lg-5">

        </div>
        <div class="col-md-6 col-sm-12 p-lg-2">
          <h2 class="display-4 fw-normal">nuestro catálogo<br>de soluciones</h2>
        </div>
          
          <div class="col-md-6 col-sm-12 p-lg-2">
            <ul class="p-hero text-start lead fw-normal" style="font-family: 'GothamLight';color: black;">
              <li>Seguridad</li>
              <li>La protección definitiva con ESET Seguridad Digital. Nuestras herramientas avanzadas te brindan una seguridad robusta y sin complicaciones. Con ESET, navega por la web con confianza</li>
              <li>Gestión sobre activos de IT</li>
            </ul>
          </div>
          <div class="col-md-6 col-sm-12 p-lg-5">
            <ul class="p-hero text-start lead fw-normal" style="font-family: 'GothamLight';color: black;">
              <li>Servicios de Soporte Técnico, Mantenimiento</li>
              <li>Respaldos de información local y en la nube</li>
              <li>Soluciones cloud y ofimática</li>
              <li>Redes / WI-FI.</li>
            </ul>
          </div>
        </div>      
    </div>
  </div>

  <div class="row px-4 pb-5 bg-dark" id="consultoria-top">
    <div class="row g-4 py-4 row-cols-1 ">
      <div class="col-md-5 text-end me-5">
        <h2 class="col-md-12 mx-auto text-end" style="color: white;">Servicios de consultoría y tecnología</h2>
      </div>
      <div class="col-md-3 ms-3 text-start">
        <p class="p-hero text-start " style="color: white; font-weight: bold;">Hardware y software</p>
        <ul class="p-hero text-start lead fw-normal" style="font-family: 'GothamLight';color: rgb(255, 255, 255);">
          <li>Datos y Almacenamiento</li>
          <li>Cómputo</li>
          <li>Licenciamiento</li>
          <li>Soporte Técnico</li>
          <li>Google Workspace</li>
          <li>Office 365</li>
          <li>Antivirus</li>
          <li>Seguridad</li>
        </ul>
      </div>
      <div class="col-md-3 ms-3 text-start">
        <p class="p-hero text-start " style="color: white; font-weight: bold;">Ingeniería e Implementación</p>
        <ul class="p-hero text-start lead fw-normal" style="font-family: 'GothamLight';color: rgb(255, 255, 255);">
          <li>Optimización de WIFI</li>
          <li>Sistemas de cableado estructurado (voz, datos, seguridad)</li>
          <li>Sistemas de control de acceso</li>
          <li>Sistemas de circuito cerrado de televisión (CCTV)</li>
          <li>Pólizas de mantenimiento</li>
        </ul>
      </div>
      
    </div>
  </div>
  <div class="row px-4 pt-1" style="background-color: white;" id="productos-top">
    <div class="row g-4 pt-2 row-cols-1 ">
      <div class="col-md-12 text-center">
        <h2 class="col-md-8 row mx-auto my-3 text-center">Especialistas en integración tecnológica</h2>
        <div class="slider col-md-12 col-sm-12 col-lg-12 mt-4">
          <div class="slide-track">
            <div class="slide">
              <img :src="imgLogo1" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo2" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo3" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo4" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo6" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo7" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo8" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo9" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo10" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo11" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo12" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo13" height="100" width="250" alt="" />
            </div>
            <div class="slide">
              <img :src="imgLogo14" height="100" width="250" alt="" />
            </div>
          </div>
        </div>
      </div>
      
      <div class="col-md-6 col-sm-12 col-lg-4 mt-5 mx-auto">
      </div>
      
    </div>
  </div>
   
  <div class="row px-4" style="background-color: white;" id="productos-top">
    <div class="row g-4 pb-2 row-cols-1 ">
      <div class="col-md-12 text-center">
        <h2 style="color: #93af29;" class="col-md-8 mx-auto my-3 text-center">Aliados tecnológicos</h2>
        
      </div>
      
      <div class="col-md-6 col-sm-12 col-lg-4 mt-5 mx-auto">
      </div>
      
    </div>
  </div>

  <ProductsIntecc/>

  <div class="bg-white py-3" id="contacto" style="padding-bottom: 0px !important;">
        <div class="container">
            <div class="row">
                <div style="text-align: center;" class="col-md-12 col-sm-12 col-lg-12  text-lg-left">
                    <p style="color:rgba(0,0,0,0.3);" class="mb-2 "> <span class="fa fa-map-marker-alt mr-2"></span> Contacto </p>
                    <div class="col-md-12 col-sm-12 col-lg-12 d-block d-sm-inline-block">
                        <p class="mb-2">
                            <i class="bi bi-envelope"></i> <a class="mr-4 font-weight-light" href="mailto:ventas@inteccsoluciones.com">ventas@inteccsoluciones.com</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>

  <div class="" style="height: 70px; background-color: #000000da;">
    <div class="col-lg-12">
      <div class="container">
        
        <div class="col-lg-12 text-center" style="font-size: small;">
        <p class="text-white font-weight-light pt-4">COPYRIGHT © 2024. TODOS LOS DERECHOS RESERVADOS</p>
        </div>
        
      </div>
    </div>
  </div>
  <HelloWorld></HelloWorld>
  <SocialChat
      icon
      :attendants="attendants"
      
    >
      <template #header >
        <p>Escribe un mensaje o llámanos</p>
      </template>
      <template  #button>
        <img
          
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        >      
      </template>
      <template #footer>
        <small>En línea</small>
      </template>
    </SocialChat>
</template>

<script>
import HelloWorld from '@/components/HelloWorld.vue';
import ProductsIntecc from '@/components/ProductsIntecc.vue';
import { SocialChat } from 'vue-social-chat'
import intecc from '@/assets/images/logos/Intecc-552.png';
import imgLogo1 from '@/assets/images/logos/1.png';
import imgLogo2 from '@/assets/images/logos/2.png';
import imgLogo3 from '@/assets/images/logos/3.png';
import imgLogo4 from '@/assets/images/logos/4.png';
import imgLogo5 from '@/assets/images/logos/5.png';
import imgLogo6 from '@/assets/images/logos/6.png';
import imgLogo7 from '@/assets/images/logos/7.png';
import imgLogo8 from '@/assets/images/logos/8.png';
import imgLogo9 from '@/assets/images/logos/9.png';
import imgLogo10 from '@/assets/images/logos/10.png';
import imgLogo11 from '@/assets/images/logos/11.png';
import imgLogo12 from '@/assets/images/logos/12.png';
import imgLogo13 from '@/assets/images/logos/13.png';
import imgLogo14 from '@/assets/images/logos/14.png';

export default {
  name: 'HomeView',
  components: {
    HelloWorld,
    SocialChat,
    ProductsIntecc
    },
    data(){
      return{
        imageSrc1 : intecc,
        imgLogo1 : imgLogo1,
        imgLogo2 : imgLogo2,
        imgLogo3 : imgLogo3,
        imgLogo4 : imgLogo4,
        imgLogo5 : imgLogo5,
        imgLogo6 : imgLogo6,
        imgLogo7 : imgLogo7,
        imgLogo8 : imgLogo8,
        imgLogo9 : imgLogo9,
        imgLogo10 : imgLogo10,
        imgLogo11 : imgLogo11,
        imgLogo12 : imgLogo12,
        imgLogo13 : imgLogo13,
        imgLogo14 : imgLogo14,
        
      };
    },
setup () {
    const attendants = [
      {
        app: 'whatsapp',
        label: 'Contacto',
        name: 'Francisco Marmolejo',
        number: '5214775762221',
        avatar: {
          src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
          alt: 'Francisco Marmolejo'
        }
      }
    ]

    return { attendants }
  }
 
}


</script>