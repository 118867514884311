<template>
    <div class="pt-1 pb-3 bg-white">
    <div class="col-md-12 col-sm-12 col-lg-12">
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/22.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/21.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-3 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/20.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/19.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/18.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/c22.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/16.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/15.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/services/eset.png" width="115"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/services/microsoft.png" width="115"/>
            </a>
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
              <img src="../assets/images/services/ubiquiti-8.png" width="145"/>
              </a>
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/14.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/13.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/12.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/11.png" width="160"/>
            </a>           
          </div>
          <div class="col-lg-3 col-sm-6 pt-sm-4 text-center">
            <a style="cursor: pointer;">
            <img src="../assets/images/logos/10.png" width="160"/>
            </a>           
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ProductsIntecc'
  }
</script>